

















import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import { CourseStores } from '@/store/course';
import { UserStores } from '@/store/users';
import moment from 'moment';
import LiveSessionTable from '@/components/progress/LiveSessionTable.vue';
import { HomeworkItem, LiveSessionItem, ParentNotificationItem, QuizItem, StudentProgressSection, TestItem } from '@/models/progress';
import QuizzesTable from '@/components/progress/QuizzesTable.vue';
import HomeworksTable from '@/components/progress/HomeworksTable.vue';
import MockTestsTable from '@/components/progress/MockTestsTable.vue';
import ParentNotificationForm from '@/components/progress/ParentNotifcationForm.vue';
import { ProgressStores } from '@/store/progress';
import EmptyPlaceholder from '@/components/common/EmptyPlaceholder.vue';
import { formatDateToMMMDDYYYYWithComma } from '@/utils/moment';
@Component({
    components: { 
        Page, 
        BackButtonLayout,
        LiveSessionTable,
        QuizzesTable,
        HomeworksTable,
        MockTestsTable,
        ParentNotificationForm,
        EmptyPlaceholder,
    },
})
export default class DetailedStudentCourses extends Vue {
    //Stores
    userDetailStore = UserStores.detail;
    courseDetailStore = CourseStores.details;
    progressStateStore = ProgressStores.state;
    progressDetailStore = ProgressStores.detail;
    //UI State
    studentName = ``;
    courseName = ``;
    selectedTab = 0;
    homeworkItems: HomeworkItem[] = [];
    notificationData: ParentNotificationItem | null = null;

    get layoutTitle() {
        return `${this.courseName} (${this.studentName})`;
    }

    get backRoute() {
        return `/progress/${this.$route.params.id}`;
    }

    get liveSessions(): LiveSessionItem[] {
        return [];
    }

    get quizzes(): QuizItem[] {
        return [];
    }

    get homeworks() {
        return this.homeworkItems;
    }

    get mockTests(): TestItem[] {
        return [];
    }

    get parentNotificationData() {
        return this.notificationData;
    }

    mounted() {
        if (!this.progressStateStore.studentState) {
            this.userDetailStore.retrieveUserById(Number(this.$route.params.id));
        } else {
            this.studentName = this.progressStateStore.studentState.name;
        }
        if (!this.progressStateStore.courseState) {
            this.courseDetailStore.fetchCourseById(Number(this.$route.params.courseId));
        } else {
            this.courseName = this.progressStateStore.courseState.name;
        }
        this.progressDetailStore.resetResponses();
        this.fetchData();
    }

    fetchData() {
        switch(this.selectedTab) {
            case 0: {
                if (this.progressDetailStore.homeworkResponse.length === 0) {
                    this.progressDetailStore.retrieveSectionData({
                        section: StudentProgressSection.homework,
                        studentId: this.$route.params.id,
                        courseId: this.$route.params.courseId
                    });
                }
                break;
            }
            case 1: {
                if (!this.progressDetailStore.notificationResponse) {
                    this.progressDetailStore.retrieveSectionData({
                        section: StudentProgressSection.parentNotification,
                        studentId: this.$route.params.id,
                    });
                }
                break;
            }
            default: { 
                break; 
            }
        }
    }

    @Watch(`userDetailStore.fetchedUserResponse`)
    updateUserData() {
        if (this.userDetailStore.fetchedUserResponse) {
            this.studentName = `${this.userDetailStore.fetchedUserResponse.firstname} ${this.userDetailStore.fetchedUserResponse.lastname}`
        }
    }

    @Watch(`courseDetailStore.fetchedCurrentCourse`)
    updateCourseData() {
        if (this.courseDetailStore.fetchedCurrentCourse) {
            this.courseName = `${this.courseDetailStore.fetchedCurrentCourse.courseName} - ${this.courseDetailStore.fetchedCurrentCourse.form.name} - ${moment(this.courseDetailStore.fetchedCurrentCourse.courseStartTime).format(`YYYY`)} - ${`${this.courseDetailStore.fetchedCurrentCourse.teacher.firstname} ${this.courseDetailStore.fetchedCurrentCourse.teacher.lastname}`}`;
        }
    }

    @Watch(`progressDetailStore.homeworkResponse`)
    updateHomeworkData() {
        if (this.progressDetailStore.homeworkResponse) {
            this.homeworkItems = [];
            this.progressDetailStore.homeworkResponse.forEach((data) => {
                this.homeworkItems.push({
                    ...data,
                    startDate: formatDateToMMMDDYYYYWithComma(data.startDate),
                    dueDate: formatDateToMMMDDYYYYWithComma(data.dueDate),
                    submissionDate: data.submissionDate.length > 0 ? formatDateToMMMDDYYYYWithComma(data.submissionDate) : '-',
                    totalTime: data.totalTime.length > 0 ? data.totalTime : '-',
                    averageScore: `${((data.marks / data.totalMarks) * 100).toFixed(2)} % (${data.marks} / ${data.totalMarks})`,
                });
            });
        }
    }

    @Watch(`progressDetailStore.notificationResponse`)
    updateNotificationData() {
        if (this.progressDetailStore.notificationResponse) {
            this.notificationData = this.progressDetailStore.notificationResponse;
        }
    }

}
