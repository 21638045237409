
































import { ParentNotificationItem } from '@/models/progress';
import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({})
export default class ParentNotificationForm extends Vue {
  @Prop({ required: true })
  data!: ParentNotificationItem;

  get isOpting() {
    return this.data.isActive;
  }

  get email() {
    return this.data.email;
  }
}
