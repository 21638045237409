



























import { TestItem } from '@/models/progress';
import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({})
export default class MockTestsTable extends Vue {
  @Prop({ required: true })
  data!: TestItem[];
}
