



























import { QuizItem } from '@/models/progress';
import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({})
export default class QuizzesTable extends Vue {
  @Prop({ required: true })
  data!: QuizItem[];
}
